<template>
	<div class="product-with-content" :class="{custom:isCustom}">
		<v-container>
			<v-row class="align-md-top justify-space-between">
				<v-col cols="12" md="4" lg="5" class="text-center relative pt-2 pt-sm-3">

					<div class="mb-4 mb-sm-8 text-center product-with-content__title">
						<h1 v-html="nameHtml"></h1>
						<h5 v-html="subnameHtml" class="mt-2"></h5>
					</div>

					<!--<div v-if="item.PREVIEW_TEXT" class="size1 color-golden mt-1">{{item.PREVIEW_TEXT}}</div>-->

					<div class="product-with-content__sticky">
						<div class="product-with-content__image">

							<div class="product-with-content__frame">
								<v-img :src="photo0"
									   class="spacer"
									   :class="boxClass"
									   contain
									   :alt="item.NAME"/>

								<transition name="fade" mode="out-in">
									<div v-if="isCustom" class="box abs" id="box"
										 :class="boxClass" key="box1" :style="customizationStyle">
										<div v-for="(index) in amount" class="cell" :key="'cell'+index">

											<v-img v-if="cells[index-1]" :key="index" class="anim-fade-in"
												   :src="cells[index-1].BOX_PICTURE || cells[index-1].PREVIEW_PICTURE"
												   @click="onBoxItemClick(index-1)"/>

											<transition name="fade-quick">
												<svgicon v-if="!cells[index-1]" name="plus" class="abs"
														 :original="true"
														 @click="onEmptyBoxItemClick(index-1)"/>
											</transition>
										</div>
									</div>
									<div v-else class="images" key="box2" @click="onImageClick">
										<v-img :src="photo0"
											   class="abs"
											   :class="{...boxClass, 'closed':photo1&&isMD}"
											   :style="isMD && item.DETAIL_STYLE?item.DETAIL_STYLE:null"
											   contain
											   :alt="item.NAME"/>
										<v-img v-if="photo1&&isMD"
											   :src="photo1"
											   class="open abs"
											   :class="boxClass"
											   :style="isMD && item.DETAIL_HOVER_STYLE?item.DETAIL_HOVER_STYLE:null"
											   contain
											   :alt="item.NAME"/>
									</div>
								</transition>

							</div>
						</div>

						<div class="product-with-content__tools" :class="{open:isCustom}">
							<a class="size2 mr-4"
							   :class="{disabled:!withClearBtn}"
							   @click="onClear">{{ page.texts.PRODUCT_CLEAR_BOX.NAME }}</a>
							<a class="size2 ml-4" @click="onRandomize">{{ page.texts.PRODUCT_RAND.NAME }}</a>
						</div>

						<!--<div v-if="offer" class="mt-8 text-center size3">
							{{offer.SHORT || offer.NAME }} variant selected
						</div>-->

						<div class="d-flex flex-column flex-md-row align-center justify-center mt-4 mt-sm-7 mt-md-10">

							<!--<btn-primary class="large d-md-none"
										 :label="'Add to cart '+priceText+' €'"
										 :loadNutritional informationing="isLoading"
										 @click="onAddToCart"/>-->

							<div v-if="isSaleOn" class="order-last order-md-first mb-4 mb-md-0 product-with-content__btn">

								<btn-primary class="large"
											 block
											 :loading="adding"
											 :disabled="isCustom && !isFullBox"
											 :label="isLG?page.texts.PRODUCT_ADD_TO_CART.NAME:page.texts.PRODUCT_ADD_TO_CART_2.NAME"
											 @click="onAddToBasket"/>

								<!--<router-link v-if="basketAmount" :to="{name:'basket'}"
											 class="basket-amount">{{basketAmount}}
								</router-link>-->
							</div>

							<div class="d-sm-none h4 color-black mb-4">{{ item.PRICE | price(2) }} €</div>
							<div
								:class="{'ml-6 ml-lg-10' : isSaleOn }"
								class="d-none d-sm-block h3 color-black mb-sm-7 mb-md-0">
								{{ item.PRICE | price(2) }} €
							</div>

						</div>

						<div v-if="withInfo" class="d-none d-md-block mt-4 mt-md-10">
							<!--<div><router-link :to="flavourRoute" class="size2">About the product</router-link></div>-->
							<div class="product-with-content__more">
								<span class="a size2--u" @click="$emit('info')">
									{{ page.texts.PRODUCT_MORE_DETAILS.NAME }}<!--More details-->
									<!--<svgicon name="arrow-right"/>-->
								</span>
							</div>
						</div>

					</div>

				</v-col>
				<v-col cols="12" md="7" lg="7" class="pt-3 pt-sm-6 pa-md-0">

					<div class="d-flex product-with-content__sets"
						 :class="{single:!withCustomization}">
						<!--:to="{name:'product-flavours', params:{code:item.CODE, sectionCode:sectionCode, type:'laurent'}}"-->
						<a @click="onCustomize(false)"
						   :class="{selected:!isCustom}">
							<div class="product-with-content__set-image">
								<v-img src="@/assets/box/laurent.svg" alt="Laurent Moreno"/>
							</div>
							<div class="ml-4 mr-4">
								<h5>{{ page.texts.PRODUCT_LM_SELECTION.NAME }}<br class="d-inline d-lg-none"/></h5>
								<p class="size2 d-none d-lg-block">{{ page.texts.PRODUCT_MATCH_FOR.NAME }}</p>
							</div>
						</a>

						<!--:to="{name:'product-flavours', params:{code:item.CODE, sectionCode:sectionCode, type:'custom'}}"-->
						<a v-if="withCustomization"
						   @click="onCustomize(true)"
						   :class="{selected:isCustom}">
							<div class="product-with-content__set-image">
								<v-img src="@/assets/box/your.svg" alt="Your choice"/>
							</div>
							<div class="ml-4">
								<h5><span v-html="page.texts.PRODUCT_YOUR_CHOICE.TEXT_1"></span></h5>
								<p class="size2 d-none d-lg-block">{{ page.texts.PRODUCT_CHOOSE_FLAVOURS.NAME }}</p>
							</div>
						</a>
					</div>

					<flavour-types :flavours="flavours"
								   :types="types"
								   :offers="offers"
								   :swipable="isXS&&isCustom"
								   class="mt-4 mt-sm-6 mt-md-10"
								   @offer="onOfferChosen"
								   @click="onFlavourClick"/>

				</v-col>
			</v-row>

			<div v-if="withInfo" class="d-md-none mt-10 product-with-content__more">
					<span class="a size2--u" @click="$emit('info')">
						{{ page.texts.PRODUCT_MORE_DETAILS.NAME }}<!--More details-->
						<!--<svgicon name="arrow-right"/>-->
					</span>
			</div>
		</v-container>
	</div>
</template>

<script>
import Vue from "vue";
import BtnPrimary from "@/components/btn-primary";
//import ProductContent from "@/views/Product/product-content";
import FlavourTypes from "@/components/flavour-types";
import {nf} from '@/utils/string';
import {scrollTo} from '@/utils/utils';
import BtnLink from "../../components/btn-link";
import vp from "@/mixins/vp";
import config from "@/config";

export default {
	name: "product-with-content",
	mixins: [vp],
	components: {
		BtnLink,
		BtnPrimary,
		//FlavoursLaurent,
		//ProductContent,
		FlavourTypes
	},
	props: ["adding", "content"],
	data() {
		return {
			amount: 0,
			cells: null,
			isCustom: false,
			isContentOpen: false,
			offerIndex: 0
		}
	},
	computed: {
		isSaleOn(){
			return config.IS_SALE_ON
		},
		page() {
			return this.$store.state.page.ProductController;
		},
		flavourRoute() {
			let anchor = "";
			if (this.section.UF_FLAVOUR_SECTION_IDS) anchor = "#section" + this.section.UF_FLAVOUR_SECTION_IDS[0];

			// todo fix the routng with named route and anchors/hashes
			return {path: '/' + anchor};
		},
		categories() {
			return this.$store.state.common.sections;
		},
		item() {
			return this.page.item;
		},
		section() {
			return this.page.section;
		},
		offers() {
			return this.page.offers;
		},
		basketAmount() {
			return this.$store.state.session?.basket?.reduce((total, el) => {
				if (Number(el.PRODUCT_ID) === Number(this.item.ID)) {
					total += el.AMOUNT;
				}
				return total;
			}, 0);
		},
		isFullBox() {
			return this.cells?.filter(el => el?.ID).length >= this.amount;
		},
		priceText() {
			return nf(this.item.PRICE, 2);
		},
		withInfo() {
			return this.item.FLAVOUR_IDS?.length || this.item.ENERGY_KJ;
		},
		withCustomization() {
			return this.item.BOX_SIZE;
		},
		withClearBtn() {
			const anyCandyIndex = this.cells?.findIndex(el => !!el);
			return anyCandyIndex !== -1;
		},
		flavours() {
			if (this.isCustom) return this.page.flavours;
			if (this.offers) return this.page.flavours;

			if (this.item.CONTENT_JSON) {
				// todo other types of content
				if (this.item.CONTENT_JSON.flavours) {
					const flavours = [];
					this.item.CONTENT_JSON.flavours.forEach(el => {
						const id = typeof el === "object" ? Number(el.id) : Number(el);
						const f = this.page.flavours?.find(fl => Number(fl.ID) === Number(id));
						if (!f) return;

						/*if ( id===294) Vue.set(f, "style", {
							width: "120px",
							//"max-width": "110px",
							//position: "absolute",
							height: "120px",
							//margin: "-10px"
						});*/

						if (typeof el === "object") {
							if (el.style) Vue.set(f, "style", el.style);
							if (el.cols) Vue.set(f, "cols", el.cols);
							/*const amount = Number(el.amount);
							if (amount > 0) {
								for (let i = 1; i <= amount; i++) {
									flavours.push(f);
								}
							}*/

						}
						flavours.push(f);
					});
					return flavours;
				}
			}

			return this.page.flavours?.filter(el => this.item.FLAVOUR_IDS?.some(flavourId => flavourId === el.ID));
		},
		collections() {
			return this.page.collections;
		},
		types() {
			if (this.isCustom) return this.page.types;
			return null;
		},
		nameHtml() {
			const item = this.item;
			//return item.name.substr(0, 1).toUpperCase() + item.name.substr(1).toLowerCase();
			let name = item.NAME;
			name = name.replace(/(^.+?)(in )?(dark|milk).+$/is, "$1");
			name = name.replace(/(^.+?)filled with .+$/is, "$1");
			name = name.replace(/(^.+?)\d+ g$/is, "$1");
			name = name.replace(/(^.+?)(\d+ pcs)$/is, "$1");
			/*if ( name.match(/(^.+?)(\d+ pcs)$/is) ) {
				name = name.replace(/(^.+?)(\d+ pcs)$/is, "$1<br>$2");
				if ( item.WEIGHT ) name += " / "+item.WEIGHT + " g";
			}
			else if ( item.WEIGHT ) name += "<br>"+item.WEIGHT + " g";*/

			return name;
		},
		subnameHtml() {
			const item = this.item;
			if (item.SUBNAME) return item.SUBNAME;

			//return item.choco.substr(0, 1).toUpperCase() + item.choco.substr(1).toLowerCase();
			let choco = null;
			if (item.NAME.match(/.+?filled with .+$/)) {
				choco = item.NAME.replace(/.+?(filled with.+$)/is, "$1");
				choco = choco.replace(/(.+?)(\d+ g$)/is, "$1<br>$2");
			} else if (item.NAME.match(/.+?(in )?(dark|milk).+$/)) {
				choco = item.NAME.replace(/.+?((in )?(dark|milk).+$)/is, "$1");
				choco = choco.replace(/(.+?)(\d+ g$)/is, "$1<br>$2");
			} else if (item.NAME.match(/\d+ g$/)) {
				// todo make this related to item.WEIGHT
				choco = item.NAME.replace(/.+?(\d+ g$)/is, "$1");
			} else if (item.NAME.match(/\d+ pcs$/)) {
				choco = item.NAME.replace(/.+?(\d+ pcs$)/is, "$1");
				if (item.WEIGHT) choco += " / " + item.WEIGHT + " g";
			}

			return choco;
		},
		boxClass() {
			return "box" + this.item.BOX_SIZE;
		},
		customizationStyle() {
			if (this.item.CUSTOMIZATION_BG) return {
				"background-image": "url('" + this.item.CUSTOMIZATION_BG + "')",
				"background-repeat": "no-repeat",
				"background-size": "contain",
			};
			return null;
		},
		photo0() {
			if (this.offer?.PHOTOS && this.offer?.PHOTOS[0]) {
				return this.offer.PHOTOS[0];
			}
			if (this.item.PHOTOS && this.item.PHOTOS[0]) return this.item.PHOTOS[0];
			//return this.item.DETAIL_PICTURE;
		},
		photo1() {
			if (this.offer?.PHOTOS && this.offer?.PHOTOS[1]) {
				return this.offer.PHOTOS[1];
			}
			//return this.item.DETAIL_PICTURE;
			//if ( this.item.DETAIL_PICTURE ) return null;
			return this.item.PHOTOS && this.item.PHOTOS[1];
		},
		offer() {
			return this.offers ? this.offers[this.offerIndex] : null;
		}
	},
	methods: {
		onOfferChosen(offer, index) {
			this.offerIndex = index;
		},
		onImageClick() {
			if (this.isMD) return;

			if (this.item.PHOTOS && this.item.PHOTOS[0] && this.item.PHOTOS[1]) {
				const temp = this.item.PHOTOS.shift();
				this.item.PHOTOS.push(temp);
			}
		},
		onAddToBasket() {
			const flavourIds = this.cells?.filter(el => el?.ID).map(el => (el.ID));
			this.$emit("basket-add", this.item, flavourIds, this.offer?.ID);
			setTimeout(() => this.onClear(), 750);
		},
		onBoxItemClick(index) {
			// убираем конфетку из коробки

			//index is 1-based
			this.cells.splice(index, 1, null);
			this.$emit("custom", this.cells.filter(el => !!el));
		},
		onEmptyBoxItemClick(index) {
			// добавляем рандомную конфетку в коробку

			//index is 1-based
			this.cells.splice(index, 1, this.randomItem());
			this.$emit("custom", this.cells.filter(el => !!el));
		},
		onFlavourClick(item) {
			if (this.isCustom) {
				// добавляем конкретную конфетку в коробку

				if (this.isXS) scrollTo("box", -100);
				const emptyCellIndex = this.cells.findIndex(el => !el);
				if (emptyCellIndex === -1) return;
				this.cells.splice(emptyCellIndex, 1, item);

				this.$emit("custom", this.cells.filter(el => !!el));
			} else {
				this.$router.push({
					name: 'flavour',
					params: {
						code: item.CODE
					},
					query: {
						back: this.$route.path
					}
				});
			}
		},
		onClear() {
			if (!this.cells) return;

			if (this.isXS) scrollTo("box", -100);
			this.cells.forEach((el, index) => this.cells.splice(index, 1, null));
			this.$emit("custom", this.cells.filter(el => !!el));
		},
		onRandomize() {
			this.onClear();
			if (this.isXS) scrollTo("box", -100);
			this.cells.forEach((el, index) => {
				setTimeout(() => {
					this.cells.splice(index, 1, this.randomItem());

					this.$emit("custom", this.cells.filter(el => !!el));
				}, index * 100);
			});
		},
		onCustomize(isCustom) {
			this.isCustom = isCustom;
			if (this.isXS && isCustom) {
				setTimeout(() => {
					scrollTo("box", -80);
				}, 500);
			}

			if (isCustom) this.$emit("custom", this.cells.filter(el => !!el));
			else this.$emit("custom", null);
		},
		randomItem() {
			const r = Math.floor(Math.random() * this.flavours.length);
			return this.flavours[r];
		},
	},
	mounted() {
		if (this.item.BOX_SIZE) {
			const xy = this.item.BOX_SIZE.split("x");
			xy[0] = xy[0];
			xy[1] = xy[1].replace(/\D/, "");
			this.amount = Number(xy[0]) * Number(xy[1]);
			this.cells = new Array(this.amount).fill(null);

			this.$emit("custom", this.cells.filter(el => !!el));

			if (this.content) {
				//console.log("CONTENT: ", this.content);
				this.isCustom = true;
				this.flavours.filter(el => this.content.some(id => id === el.ID)).forEach(f => {
					setTimeout(() => {
						this.onFlavourClick(f);
					}, 500);
				});
			}
		}
	}
}
</script>

<style lang="scss">
@import "@/styles/base";

.product-with-content {

	&__title {
		position: relative;
		z-index: 3;

		&:before {
			@extend .abs;
			content: "";
			width: 100%;
			height: 100%;
			background: $white;
			//mix-blend-mode: darken;
			opacity: 1;
			filter: blur(16px);
			max-width: 320px;
			//max-height: 320px;
			z-index: -1;
			//border: 1px solid red;
		}

		h1 {
			font-size: $fs-h4;
			letter-spacing: $ls-h4;
			line-height: $lh-h4;

			@include up($sm) {
				font-size: $fs-h1;
				letter-spacing: $ls-h1;
				line-height: $lh-h1;
			}
		}
	}

	&__sticky {

		@include up($md) {
			position: sticky;
			top: 200px;
		}
	}

	&__content {
		background: $gray-lightest;
		border-radius: 24px 24px 0 0;

		display: flex;
		align-content: center;
		flex-direction: column;
		//padding: 0 20px;

		position: fixed;
		z-index: 2;
		left: 50%;
		bottom: 0;
		width: 60%;
		min-width: 280px;
		height: 64px;
		transform: translateX(-50%);

		@include transition();

		@include up($md) {
			display: block;
			background: unset;
			border-radius: unset;
			align-content: flex-start;
			position: unset;
			height: unset;
			min-height: unset;
			width: unset;
			min-width: unset;
			transform: unset;
		}

		&.open {
			height: 65vh;
			width: 101%;
			//background: $white-alpha;

			@include up($md) {
				height: unset;
				width: unset;
			}
		}

		/*.flavour-types {
			display: none;
			@include up($md) {
				display: block;
			}
		}*/
	}

	&__sets {
		max-width: 320px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;

		@include up($sm) {
			max-width: 360px;
		}

		@include up($md) {
			max-width: 460px;
		}

		@include up($lg) {
			max-width: 640px;
		}

		a {
			display: flex;
			align-items: center;
			//flex-direction: column;
			//text-align: center;
			//width: 48%;

			/*@include up($md) {
				//flex-direction: row;
				//text-align: left;
			}*/

			&:hover {
				.product-with-content__set-image {
					border-color: $golden;
				}
			}

			&.selected {
				.product-with-content__set-image {
					border-color: $golden;
				}
			}

			&:not(:hover):not(.selected) {
				color: $gray !important;
			}

			h5 {
				font-size: $fs-h6;
				line-height: $lh-h6;

				@include up($lg) {
					font-size: $fs-h5;
					line-height: $lh-h5;
				}
			}
		}

		&.single {
			//padding-top: 200px;

			a {
				cursor: default;

				&:hover {
					opacity: 1;
				}
			}
		}
	}

	&__set {
		&-image {
			border: 2px solid transparent;
			border-radius: 50%;
			padding: 5px;
			width: 54px;
			height: 54px;
			@include transition();

			@include up($md) {
				width: 78px;
				height: 78px;
			}

			.v-image {
				width: 40px;
				height: 40px;

				@include up($md) {
					width: 64px;
					height: 64px;
				}
			}
		}

	}

	&__image {
		position: relative;
		max-width: 320px;
		max-height: 320px;
		//min-height: 220px;
		margin: 0 auto;
		display: flex;
		justify-content: center;
	}

	&__frame {
		position: relative;
		max-width: 280px;
		max-height: 280px;

		@include up($sm) {
			max-width: unset;
			max-height: unset;
		}

		.spacer {
			opacity: 0;
			z-index: -1 !important;
		}

		/*.images {
			position: relative;
			width: 100%;
			height: 100%;
		}*/

		.v-image {
			height: 100%;
			//mix-blend-mode: multiply;
			//background-blend-mode: exclusion;
			filter: brightness(1.01); // contrast(1.02);	// для исчезновения в jpg серого фона
			@include transition();

			@include up($sm) {
				height: unset;
			}

			&.closed {
				z-index: 2;
				//cursor: pointer;

				&:hover {
					opacity: 0;

					~ .open {
						opacity: 1;
					}
				}
			}

			&.open {
				opacity: 0;
				z-index: 1;

				/*&:hover {
					opacity: 1;

					~ .closed {
						opacity: 0;
					}
				}*/
			}

			&.box3x1m {

				@include up($md) {
					width: 380px;
					max-width: 380px;
				}

				@include up($lg) {
					width: 440px;
					max-width: 440px;
				}
			}
		}

		.box {
			width: 100%;
			height: 100%;
			border: 5px solid $golden-light;
			//max-width: 380px;
			//max-height: 380px;
			//min-width: 140px;
			//min-height: 140px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			//padding: 12px;

			.cell {
				position: relative;
				//padding: 12px;
				//box-sizing: border-box;
				border-right: 1px solid $golden-light;
				border-bottom: 1px solid $golden-light;
				cursor: pointer;
				display: flex;
				justify-content: center;
				align-items: center;
				background: $white;

				&:hover {
					svg {
						opacity: 0.6;
					}

					.v-image {
						opacity: 0.8;
					}
				}

				svg {
					@include transition();
					fill: none;
					//stroke: $golden;
					width: 80%;
					height: 80%;
				}

				.v-image {
					position: relative;
					z-index: 1;
					max-width: 85%;
					height: 85%;
				}
			}

			@include up($md) {
				border: 7px solid $golden-light;
			}

			&.box2x1 {
				.cell {
					width: 50.0%;
					height: 100%;

					&:nth-child(2n) {
						border-right: none;
					}

					&:nth-last-child(-n+2) {
						border-bottom: none;
					}
				}
			}

			&.box2x2 {
				.cell {
					width: 50.0%;
					height: 50.0%;

					&:nth-child(2n) {
						border-right: none;
					}

					&:nth-last-child(-n+2) {
						border-bottom: none;
					}
				}
			}

			&.box2x3 {
				//width: 80%;
				max-width: 190px;

				@include up($sm) {
					max-width: unset;
				}

				.cell {
					width: 50.0%;
					height: 33.333%;

					&:nth-child(2n) {
						border-right: none;
					}

					&:nth-last-child(-n+2) {
						border-bottom: none;
					}
				}
			}

			&.box3x1m {
				padding: 16px 100px 16px 2px;
				border: 0;

				@include up($sm) {
					padding: 25px 160px 25px 3px;
				}

				@include up($md) {
					padding: 23px 140px 23px 4px;
					width: 380px;
				}

				@include up($lg) {
					padding: 26px 160px 26px 4px;
					width: 440px;
				}

				.cell {
					width: 33.333%;
					height: 100%;
					border-bottom: none;

					/*&:nth-child(3n) {
						border-right: none;
					}*/
				}
			}

			&.box3x1c {
				width: 144px;

				@include up($sm) {
					width: 168px;
				}

				.cell {
					width: 33.333%;
					height: 100%;
					border-bottom: none;
					//padding: 0 10px;

					/*&:nth-child(3n) {	// это рушит немножко картинку
						border-right: none;
					}*/

					.v-image {
						height: 96%;
					}
				}
			}

			&.box9x1c {
				width: 100% x;

				@include up($sm) {
					width: 450px;
				}

				.cell {
					width: 11.111%;
					height: 100%;
					border-bottom: none;
					padding: 0 4px;

					/*&:nth-child(3n) {	// это рушит немножко картинку
						border-right: none;
					}*/

					.v-image {
						height: 100%;
					}
				}
			}

			&.box3x3 {
				.cell {
					width: 33.333%;
					height: 33.333%;

					&:nth-child(3n) {
						border-right: none;
					}

					&:nth-last-child(-n+3) {
						border-bottom: none;
					}
				}
			}
		}
	}

	&__tools {
		@include transition();
		height: 0;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		opacity: 0;

		&.open {
			opacity: 1;
			height: 32px;
		}

		a.disabled {
			cursor: default;
			opacity: 0.3;
		}
	}

	&__btn {
		width: 280px;
		@include up($sm) {
			width: 320px;
		}
		@include up($md) {
			width: unset;
		}
	}

	&__more {
		text-align: center;
		span {
			display: inline-flex;
			align-items: center;

			svg {
				width: 12px;
				//transform: rotate(180deg);
				margin-left: 4px;
			}
		}
	}
}
</style>