<template>
	<div class="product-1kilo" :class="{custom:isCustom}">
		<v-container>
			<v-row class="align-md-top justify-space-between">
				<v-col cols="12" class="text-center relative pt-2 pt-sm-3">

					<div class="mb-2 text-center product-1kilo__title">
						<h1 v-html="nameHtml"></h1>
						<h5 v-html="chocoHtml" class="mt-2"></h5>
					</div>

					<div class="product-1kilo__image" @click="onImageClick">

						<v-img :src="photo0"
							   class="closed"
							   contain
							   :alt="item.NAME"/>
						<v-img v-if="photo1"
							   :src="photo1"
							   class="open abs"
							   contain
							   :alt="item.NAME"/>
					</div>

					<div class="d-flex flex-column flex-md-row justify-center align-center mt-sm-8">
						<div class="d-sm-none h4 color-black mb-4">{{item.PRICE | price(2)}} €</div>
						<div
							:class="{'ml-lg-10' : isSaleOn }"
							class="d-none d-sm-block h3 color-black mb-sm-7 mb-md-0">{{item.PRICE | price(2)}} €</div>

						<div v-if="isSaleOn" class="order-md-first product-1kilo__btn">
							<btn-primary class="large"
										 block
										 :label="isLG?page.texts.PRODUCT_ADD_TO_CART.NAME:page.texts.PRODUCT_ADD_TO_CART_2.NAME"
										 :loading="adding"
										 @click="onAddToBasket"/>

							<!--								<router-link v-if="basketAmount" :to="{name:'basket'}"
																		 class="basket-amount">{{basketAmount}}</router-link>-->

						</div>
					</div>

				</v-col>
				<v-col cols="12" class="pt-4 pt-sm-12">

					<div class="d-flex product-1kilo__sets"
						 :class="{single:!withCustomization}">
						<!--:to="{name:'product-flavours', params:{code:item.CODE, sectionCode:sectionCode, type:'laurent'}}"-->
						<a @click="onCustomize(false)"
						   :class="{selected:!isCustom}">
							<div class="product-1kilo__set-image">
								<v-img src="@/assets/box/laurent.svg" alt="Laurent Moreno"/>
							</div>
							<div class="ml-4 mr-4">
								<h5>{{page.texts.PRODUCT_LM_SELECTION.NAME}}<!--Laurent Moreno’s Selection--><br class="d-inline d-lg-none"/></h5>
								<p class="size2 d-none d-lg-block"><!--А perfect match for this box-->{{page.texts.PRODUCT_MATCH_FOR.NAME}}</p>
							</div>
						</a>

						<!--:to="{name:'product-flavours', params:{code:item.CODE, sectionCode:sectionCode, type:'custom'}}"-->
						<a v-if="withCustomization"
						   @click="onCustomize(true)"
						   :class="{selected:isCustom}">
							<div class="product-1kilo__set-image">
								<v-img src="@/assets/box/your.svg" alt="Your choice"/>
							</div>
							<div class="ml-4">
								<h5>Your<br class="d-inline d-sm-none"/> choice</h5>
								<p class="size2 d-none d-lg-block">Choose your flavours</p>
							</div>
						</a>
					</div>

					<flavour-types :flavours="flavours"
								   :types="types"
								   :swipable="isXS&&isCustom"
								   class="mt-4 mt-sm-6 mt-md-10"
								   @click="onFlavourClick"/>

				</v-col>
			</v-row>

			<div v-if="withInfo" class="mt-10 text-center product-1kilo__more">
				<span class="a size2--u" @click="$emit('info')">
					{{page.texts.PRODUCT_MORE_DETAILS.NAME}}<!--More details-->
					<!--<svgicon name="arrow-right"/>-->
				</span>
			</div>

		</v-container>
	</div>
</template>

<script>
	import Vue from "vue";
	import BtnPrimary from "@/components/btn-primary";
	//import ProductContent from "@/views/Product/product-content";
	import FlavourTypes from "@/components/flavour-types";
	import {nf} from '@/utils/string';
	import {scrollTo} from '@/utils/utils';
	import BtnLink from "../../components/btn-link";
	import vp from "@/mixins/vp";
	import config from "@/config";

	export default {
		name: "product-1kilo",
		mixins: [vp],
		components: {
			BtnLink,
			BtnPrimary,
			//FlavoursLaurent,
			//ProductContent,
			FlavourTypes
		},
		props: ["adding", "content"],
		data() {
			return {
				amount: 0,
				cells: null,
				isCustom: false,
				isContentOpen: false,
			}
		},
		computed: {
			isSaleOn(){
				return config.IS_SALE_ON
			},
			page() {
				return this.$store.state.page.ProductController;
			},
			flavourRoute() {
				let anchor = "";
				if (this.section.UF_FLAVOUR_SECTION_IDS) anchor = "#section" + this.section.UF_FLAVOUR_SECTION_IDS[0];

				// todo fix the routng with named route and anchors/hashes
				return {path: '/' + anchor};
			},
			categories() {
				return this.$store.state.common.sections;
			},
			item() {
				return this.page.item;
			},
			section() {
				return this.page.section;
			},
			basketAmount() {
				return this.$store.state.session?.basket?.reduce((total, el) => {
					if (Number(el.PRODUCT_ID) === Number(this.item.ID)) {
						total += el.AMOUNT;
					}
					return total;
				}, 0);
			},
			priceText() {
				return nf(this.item.PRICE, 2);
			},
			withInfo() {
				return this.item.FLAVOUR_IDS?.length || this.item.ENERGY_KJ;
			},
			withCustomization() {
				return this.item.BOX_SIZE;
			},
			flavours() {
				if (this.isCustom) return this.page.flavours;

				if (this.item.CONTENT_JSON) {
					// todo other types of content
					if (this.item.CONTENT_JSON.flavours) {
						const flavours = [];
						this.item.CONTENT_JSON.flavours.forEach(el => {
							const id = typeof el === "object" ? Number(el.id) : Number(el);
							const f = this.page.flavours?.find(fl => Number(fl.ID) === Number(id));
							if (!f) return;

							/*if ( id===294) Vue.set(f, "style", {
								width: "120px",
								//"max-width": "110px",
								//position: "absolute",
								height: "120px",
								//margin: "-10px"
							});*/

							if (typeof el === "object") {
								if (el.style) Vue.set(f, "style", el.style);
								if (el.cols) Vue.set(f, "cols", el.cols);
								/*const amount = Number(el.amount);
								if (amount > 0) {
									for (let i = 1; i <= amount; i++) {
										flavours.push(f);
									}
								}*/

							}
							flavours.push(f);
						});
						return flavours;
					}
				}

				return this.page.flavours?.filter(el => this.item.FLAVOUR_IDS?.some(flavourId => flavourId === el.ID));
			},
			collections() {
				return this.page.collections;
			},
			types() {
				if (this.isCustom) return this.page.types;
				return null;
			},
			nameHtml() {
				const item = this.item;
				//return item.name.substr(0, 1).toUpperCase() + item.name.substr(1).toLowerCase();
				let name = item.NAME;
				name = name.replace(/(^.+?)(in )?(dark|milk).+$/is, "$1");
				name = name.replace(/(^.+?)filled with .+$/is, "$1");
				name = name.replace(/(^.+?)\d+ g$/is, "$1");
				name = name.replace(/(^.+?)(\d+ pcs)$/is, "$1");
				/*if ( name.match(/(^.+?)(\d+ pcs)$/is) ) {
					name = name.replace(/(^.+?)(\d+ pcs)$/is, "$1<br>$2");
					if ( item.WEIGHT ) name += " / "+item.WEIGHT + " g";
				}
				else if ( item.WEIGHT ) name += "<br>"+item.WEIGHT + " g";*/

				return name;
			},
			chocoHtml() {
				const item = this.item;
				//return item.choco.substr(0, 1).toUpperCase() + item.choco.substr(1).toLowerCase();
				let choco = null;
				if (item.NAME.match(/.+?filled with .+$/)) {
					choco = item.NAME.replace(/.+?(filled with.+$)/is, "$1");
					choco = choco.replace(/(.+?)(\d+ g$)/is, "$1<br>$2");
				} else if (item.NAME.match(/.+?(in )?(dark|milk).+$/)) {
					choco = item.NAME.replace(/.+?((in )?(dark|milk).+$)/is, "$1");
					choco = choco.replace(/(.+?)(\d+ g$)/is, "$1<br>$2");
				} else if (item.NAME.match(/\d+ g$/)) {
					// todo make this related to item.WEIGHT
					choco = item.NAME.replace(/.+?(\d+ g$)/is, "$1");
				} else if (item.NAME.match(/\d+ pcs$/)) {
					choco = item.NAME.replace(/.+?(\d+ pcs$)/is, "$1");
					if (item.WEIGHT) choco += " / " + item.WEIGHT + " g";
				}

				return choco;
			},
			withClearBtn() {
				const anyCandyIndex = this.cells?.findIndex(el => !!el);
				return anyCandyIndex !== -1;
			},
			customizationStyle() {
				if (this.item.CUSTOMIZATION_BG) return {
					"background-image": "url('" + this.item.CUSTOMIZATION_BG + "')",
					"background-repeat": "no-repeat",
					"background-size": "contain",
				};
				return null;
			},
			photo0() {
				//return this.item.PREVIEW_PICTURE;
				if (this.item.PHOTOS && this.item.PHOTOS[0]) return this.item.PHOTOS[0];
				//return this.item.DETAIL_PICTURE;
			},
			photo1() {
				//return this.item.DETAIL_PICTURE;
				//if ( this.item.DETAIL_PICTURE ) return null;
				return this.item.PHOTOS && this.item.PHOTOS[1];
			},
		},
		methods: {
			onImageClick() {
				if (this.isMD) return;

				if (this.item.PHOTOS && this.item.PHOTOS[0] && this.item.PHOTOS[1]) {
					const temp = this.item.PHOTOS.shift();
					this.item.PHOTOS.push(temp);
				}
			},
			onAddToBasket() {
				this.$emit("basket-add");
			},
			onBoxItemClick(index) {
				//index is 1-based
				this.cells.splice(index, 1, null);
			},
			onEmptyBoxItemClick(index) {
				// fill with random
				this.cells.splice(index, 1, this.randomItem());
			},
			onFlavourClick(item) {
				if (this.isCustom) {
					if (this.isXS) scrollTo("box", -100);
					const emptyCellIndex = this.cells.findIndex(el => !el);
					if (emptyCellIndex === -1) return;
					this.cells.splice(emptyCellIndex, 1, item);
				} else {
					this.$router.push({
						name: 'flavour',
						params: {
							code: item.CODE
						},
						query: {
							back: this.$route.path
						}
					});
				}
			},
			onClear() {
				if (this.isXS) scrollTo("box", -100);
				this.cells.forEach((el, index) => this.cells.splice(index, 1, null));
			},
			onRandomize() {
				this.onClear();
				if (this.isXS) scrollTo("box", -100);
				this.cells.forEach((el, index) => {
					setTimeout(() => {
						this.cells.splice(index, 1, this.randomItem());
					}, index * 100);
				});
			},
			onCustomize(isCustom) {
				this.isCustom = isCustom;
				if (this.isXS && isCustom) {
					setTimeout(() => {
						scrollTo("box", -80);
					}, 500);
				}
			},
			randomItem() {
				const r = Math.floor(Math.random() * this.flavours.length);
				return this.flavours[r];
			},
		},
		mounted() {
			if (this.item.BOX_SIZE) {
				const xy = this.item.BOX_SIZE.split("x");
				xy[0] = xy[0];
				xy[1] = xy[1].replace(/\D/, "");
				this.amount = Number(xy[0]) * Number(xy[1]);
				console.log("amount", this.amount);
				this.cells = new Array(this.amount).fill(null);

				if (this.content) {
					this.isCustom = true;
					this.flavours.filter(el => this.content.some(id => id === el.ID)).forEach(f => {
						this.onFlavourClick(f);
					});
				}
			}
		}
	}
</script>

<style lang="scss">
	.product-1kilo {
		&__title {
			h1 {
				font-size: $fs-h4;
				letter-spacing: $ls-h4;
				line-height: $lh-h4;

				@include up($sm) {
					font-size: $fs-h1;
					letter-spacing: $ls-h1;
					line-height: $lh-h1;
				}
			}
		}

		&__content {
			background: $gray-lightest;
			border-radius: 24px 24px 0 0;

			display: flex;
			align-content: center;
			flex-direction: column;
			//padding: 0 20px;

			position: fixed;
			z-index: 2;
			left: 50%;
			bottom: 0;
			width: 60%;
			min-width: 280px;
			height: 64px;
			transform: translateX(-50%);

			@include transition();

			@include up($md) {
				display: block;
				background: unset;
				border-radius: unset;
				align-content: flex-start;
				position: unset;
				height: unset;
				min-height: unset;
				width: unset;
				min-width: unset;
				transform: unset;
			}

			&.open {
				height: 65vh;
				width: 101%;
				//background: $white-alpha;

				@include up($md) {
					height: unset;
					width: unset;
				}
			}

			/*.flavour-types {
				display: none;
				@include up($md) {
					display: block;
				}
			}*/
		}

		&__sets {
			max-width: 320px;
			margin: 0 auto;
			display: flex;
			justify-content: center;

			@include up($sm) {
				max-width: 360px;
			}

			@include up($md) {
				max-width: 460px;
			}

			@include up($lg) {
				max-width: 640px;
			}

			a {
				display: flex;
				align-items: center;
				//flex-direction: column;
				//text-align: center;
				//width: 48%;

				/*@include up($md) {
					//flex-direction: row;
					//text-align: left;
				}*/

				&:hover {
					.product-1kilo__set-image {
						border-color: $golden;
					}
				}

				&.selected {
					.product-1kilo__set-image {
						border-color: $golden;
					}
				}

				&:not(:hover):not(.selected) {
					color: $gray !important;
				}

				h5 {
					font-size: $fs-h6;
					line-height: $lh-h6;

					@include up($lg) {
						font-size: $fs-h5;
						line-height: $lh-h5;
					}
				}
			}

			&.single {
				//padding-top: 200px;

				a {
					cursor: default;

					&:hover {
						opacity: 1;
					}
				}
			}
		}

		&__set {
			&-image {
				border: 2px solid transparent;
				border-radius: 50%;
				padding: 5px;
				width: 54px;
				height: 54px;
				@include transition();

				@include up($md) {
					width: 78px;
					height: 78px;
				}

				.v-image {
					width: 40px;
					height: 40px;

					@include up($md) {
						width: 64px;
						height: 64px;
					}
				}
			}

		}

		&__image {
			position: relative;
			height: 127px;
			//min-height: 220px;
			margin: 0 auto;
			display: flex;
			justify-content: center;

			.v-image {
				filter: brightness(1.01); // contrast(1.02);	// для исчезновения в jpg серого фона
				@include transition();

				&.closed {
					//transform: translateY(-2%);
					z-index: 1;

					@include up($md) {
						&:hover {
							opacity: 0;

							~ .open {
								opacity: 1;
							}
						}
					}
				}

				/*&.open {
					transform: translateX(-50%) translateY(-52%);
				}*/
			}
		}

		&__btn {
			width: 316px;

			@include up($sm) {
				width: 320px;
			}
			@include up($md) {
				width: unset;
			}

			.btn-primary {
			}
		}

		&__more {
			span {
				display: inline-flex;
				align-items: center;

				svg {
					width: 12px;
					//transform: rotate(180deg);
					margin-left: 4px;
				}
			}
		}
	}
</style>