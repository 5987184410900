<template>
	<div class="product-info">
		<transition name="fade">
			<v-container v-if="isOpen">
				<h3 class="text-center mt-12" v-html="item.NAME"></h3>
				<h5 class="text-center mb-12 mt-2">{{this.page.texts.PRODUCT_NUT_INFO.NAME}}<!--Nutritional information--></h5>
				<v-row>
					<!--<v-col cols="6" sm="3" class="product-info__grid-block align-sm-start">
						<div class="h6">Shelf life</div>
						<div class="h6" v-html="shelfLife"></div>
					</v-col>-->
					<!--<v-col cols="6" sm="3" class="product-info__grid-block align-sm-start pl-md-9 pl-lg-15">
						<div class="h6">Temperature</div>
						<div class="h6" v-html="temperature"></div>
					</v-col>-->
					<v-col cols="6" md="3" lg="2" class="product-info__grid-block">
						<div class="h6 mb-2">{{this.page.texts.PRODUCT_NET_WEIGHT.NAME}}</div>
						<div class="h6" v-html="netWeight"></div>
					</v-col>
					<v-col cols="6" md="3" lg="2" class="product-info__grid-block order-md-1">
						<div class="h6 mb-2">{{this.page.texts.PRODUCT_PRICE_PER_KG.NAME}}</div>
						<div class="h6" v-html="pricePerKg"></div>
					</v-col>
					<v-col cols="12" md="12" lg="4" class="product-info__grid-block order-md-3">
						<div class="h6 mb-2">{{this.page.texts.PRODUCT_VALUE_100.NAME}}</div>
						<p class="size2">{{nutrition}}</p>
					</v-col>
					<v-col cols="12" md="6" lg="4" class="product-info__grid-block order-md-2 order-lg-3">
						<div class="h6 mb-2">{{this.page.texts.PRODUCT_ALLERGENS.NAME}}</div>
						<p class="size2" v-html="allergens"></p>
					</v-col>
					<v-col cols="12" lg="12" class="product-info__grid-block --nb order-md-3 text-left">
						<div class="h6 text-left mb-2">{{this.page.texts.PRODUCT_INGREDIENTS.NAME}}</div>
						<div class="size2" v-html="ingredients"></div>
					</v-col>
				</v-row>

				<div class="text-center">
								<span class="close a size2--u color-black" @click="$emit('close')">
									{{this.page.texts.PRODUCT_CLOSE.NAME}}<!--Close-->
									<svgicon name="cross"/>
								</span>
				</div>
			</v-container>
		</transition>
	</div>
</template>

<script>
	import {nf} from "@/utils/string";
	import Config from "@/config";

	export default {
		name: "product-info",
		components: {},
		props: ["isOpen", "customFlavours"],
		data() {
			return {}
		},
		computed: {
			page() {
				return this.$store.state.page.ProductController;
			},
			item() {
				return this.page.item;
			},
			/**
			 * Возвращает список конфет, входящих в коробку на основе FLAVOUR_IDS.
			 */
			flavours() {
				return this.page.flavours?.filter(el => this.item.FLAVOUR_IDS?.some(flavourId => flavourId === el.ID));
			},
			/**
			 * Возвращает список конфет, входящих в коробку, на основе привязки customFlavours, FLAVOUR_IDS + CONTENT_JSON.
			 * @returns {*}
			 */
			contentFlavours() {
				if (!this.flavours?.length) return null;

				if (this.customFlavours?.length) return this.customFlavours;

				let flavours = [];
				if (this.item.CONTENT_JSON.flavours) {
					this.item.CONTENT_JSON.flavours.forEach(el => {
						const id = typeof el === "object" ? Number(el.id) : Number(el);
						const f = this.page.flavours?.find(fl => Number(fl.ID) === Number(id));
						if (f) flavours.push(f);
					});
				} else flavours = this.flavours;
				return flavours;
			},
			shelfLife() {
				if (this.item.SHELF_LIFE) return this.item.SHELF_LIFE;
				if (!this.contentFlavours?.length) return "";
				return this.contentFlavours[0].SHELF_LIFE;
			},
			netWeight() {
				if (this.item.NET_WEIGHT) return this.item.NET_WEIGHT;

				// collect from flavours
				if (!this.contentFlavours?.length) return "";
				return this.contentFlavours.reduce((total, el) => {
					let w = el.NET_WEIGHT?.replace(/[^\d+,\.]/igs, "");
					w = w.replace(/,/igs, ".");
					w = Number(w);

					if (isNaN(w)) return total;
					return total + w;
				}, 0) + " g";
			},
			temperature() {
				if (this.item.TEMPERATURE) return this.item.TEMPERATURE;
				if (!this.contentFlavours?.length) return "";
				return this.contentFlavours[0].TEMPERATURE;
			},
			pricePerKg() {
				if (this.item.PRICE_PER_KG) return this.item.PRICE_PER_KG;
				if (!this.contentFlavours?.length) return "";
				return this.contentFlavours[0].PRICE_PER_KG;
			},
			nutrition() {
				let texts = [];
				if (this.item.ENERGY_KJ && this.item.KCAL) texts.push("Energy " + this.item.ENERGY_KJ + " kj / " + this.item.KCAL + " kcal");
				if (this.item.FATS) texts.push("fat " + nf(this.item.FATS, 2) + " g");
				if (this.item.CARBOHYDRATES) texts.push("carbohydrates " + nf(this.item.CARBOHYDRATES, 2) + " g");
				if (this.item.PROTEINS) texts.push("proteins " + nf(this.item.PROTEINS, 2) + " g");
				if (texts.length) return texts.join(", ");

				// collect from flavours
				if (!this.contentFlavours?.length) return "";
				let energy = 0, kcal = 0, fats = 0, carbohydrates = 0, proteins = 0;
				this.contentFlavours.forEach(el => {
					if (el.ENERGY_KJ && el.KCAL) {
						energy += Number(el.ENERGY_KJ);
						kcal += Number(el.KCAL);
					}
					if (el.FATS) fats += Number(el.FATS);
					if (el.CARBOHYDRATES) carbohydrates += Number(el.CARBOHYDRATES);
					if (el.PROTEINS) proteins += Number(el.PROTEINS);
				});

				texts = [];
				if (energy && kcal) texts.push("Energy " + nf(energy / this.contentFlavours.length, 0, ".", "") + " kj / " + nf(kcal / this.contentFlavours.length, 0, ".", "") + " kcal");
				if (fats) texts.push("fat " + nf(fats / this.contentFlavours.length, 2) + " g");
				if (carbohydrates) texts.push("carbohydrates " + nf(carbohydrates / this.contentFlavours.length, 2) + " g");
				if (proteins) texts.push("proteins " + nf(proteins / this.contentFlavours.length, 2) + " g");
				return texts.join(", ");
			},
			allergens() {
				if (this.item.ALLERGENS) return this.item.ALLERGENS;
				if (!this.contentFlavours?.length) return "";

				// calc based on flavours
				let alergens = [];

				this.contentFlavours.forEach(el => {
					if (!el.ALLERGENS) return;

					const items = el.ALLERGENS.split(",");
					items.forEach(item => {
						item = item.trim().toLowerCase();
						if (!alergens.find(a => a.toLowerCase() === item)) alergens.push(item);
					});
				});

				if (alergens[0]) alergens[0] = alergens[0].charAt(0).toUpperCase() + alergens[0].slice(1);

				return alergens.join(", ");
			},
			ingredients() {
				if (this.item.INGREDIENTS) return this.item.INGREDIENTS;
				if (!this.contentFlavours?.length) return "";

				const ids = [];
				const texts = this.contentFlavours.reduce((texts, el) => {
					if (!el.INGREDIENTS) return texts;
					if (ids.find(id => id === el.ID)) return texts;
					ids.push(el.ID);
					const name = "<b>" + el.NAME.replace(/\<br\/?\>/igs, " ") + "</b>";
					texts.push(name + ": " + el.INGREDIENTS.replaceAll(Config.ALLERGENS_REGEXP, "<b>$1</b>").toLowerCase());
					return texts;
				}, []);
				return "<p>" + texts.join(".</p><p>") + "</p>";
			}
		},
		methods: {},
	}
</script>

<style lang="scss">
	.product-info {
		max-width: 1050px;
		margin: 0 auto;

		&__grid {
			&-block {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				padding: 16px 0 !important;
				text-align: center;
				align-items: center;
				border-bottom: 1px solid $gray-light;

				&:first-child {
					border-top: 1px solid $gray-light;
				}

				&:nth-child(2) {
					border-top: 1px solid $gray-light;
				}

				&:nth-child(4) {
					@include up($md) {
						border-top: 1px solid $gray-light;
					}
				}

				&:nth-child(3) {
					@include up($lg) {
						border-top: 1px solid $gray-light;
					}
				}

				p {
					line-height: normal;
				}
			}
		}

		/*.h6 {
			color: $black;
		}*/

		/*b {
			color: $black;
			//font-weight: 900;
		}*/

		.close {
			display: inline-flex;
			margin-top: 40px;
			align-items: center;

			svg {
				width: 12px;
				height: 12px;
				margin-left: 8px;
				stroke: $black;
				/*@include up($md) {
					width: 12px;
					height: 12px;
				}*/
			}

			/*&:hover {
				stroke: $black;
			}*/
		}
	}
</style>