<template>
	<div class="product-without-content">
		<v-container>
			<v-row class="align-md-center justify-space-between">
				<v-col cols="12" md="6" class="text-center pt-2 pt-sm-3">

					<div class="mb-4 mb-sm-8 d-md-none product-without-content__title">
						<h1 v-html="nameHtml"></h1>
						<h5 v-html="subnameHtml" class="mt-2"></h5>
					</div>

					<div class="product-without-content__image">
						<div class="hover" @click="onImageClick">
							<v-img :src="photo0"
								   :class="{closed:photo1}"
								   contain
								   :alt="item.NAME"/>
							<v-img v-if="photo1&&isMD"
								   :src="photo1"
								   class="open"
								   :style="isMD && item.DETAIL_HOVER_STYLE?item.DETAIL_HOVER_STYLE:null"
								   contain :alt="item.NAME"/>
						</div>
					</div>

				</v-col>
				<v-col cols="12" md="6" class="text-center text-md-left pl-md-8">

					<div class="product-without-content__text">

						<div class="d-none d-md-block product-without-content__title">
							<h1 v-html="nameHtml"></h1>
							<h5 v-html="subnameHtml" class="mt-2"></h5>
						</div>

						<div class="d-flex flex-column mt-md-8">

							<div class="d-flex flex-column flex-md-row align-center justify-center justify-md-start">

								<div v-if="isSaleOn" class="order-last order-md-first">

									<btn-primary class="large"
												 :loading="adding"
												 :label="isLG ? page.texts.PRODUCT_ADD_TO_CART.NAME : page.texts.PRODUCT_ADD_TO_CART_2.NAME"
												 @click="onAddToBasket" />

									<!--<div class="rel">
										<btn-primary class="large"
													 :label="isLG?page.texts.PRODUCT_ADD_TO_CART.NAME:page.texts.PRODUCT_ADD_TO_CART_2.NAME"
													 :loading="adding"
													 @click="onAddToBasket"/>

										<router-link v-if="basketAmount" :to="{name:'basket'}"
													 class="basket-amount">{{basketAmount}}
										</router-link>
									</div>-->
								</div>

								<div class="d-sm-none h4 color-black mb-4">{{item.PRICE | price(2)}} €</div>
								<div
									:class="{'ml-md-10' : isSaleOn }"
									class="d-none d-sm-block h3 color-black mb-sm-7 mb-md-0">{{item.PRICE | price(2)}} €</div>
							</div>

							<p v-if="item.IS_RANDOM"
							   class="order-md-first mt-10 mt-md-0 mb-md-6 product-without-content__note">
								{{ page.texts.PRODUCT_RANDOM_FILL.TEXT_1 }}<!--This box is random
								filled with our selection,
								if you really appreciate a particular flavour let us know
								we'll call you back to meet your request.--></p>
						</div>

						<div v-if="withInfo" class="mt-10 product-without-content__more">
						<span class="a size2--u" @click="$emit('info')">
							{{ page.texts.PRODUCT_MORE_DETAILS.NAME }}<!--More details-->
							<!--<svgicon name="arrow-right"/>-->
						</span>
						</div>

					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import BtnPrimary from "@/components/btn-primary";
import {nf} from '@/utils/string';
import {scrollTo} from '@/utils/utils';
import vp from "@/mixins/vp";
import config from "@/config";

export default {
	name: "product-without-content",
	components: {
		BtnPrimary,
	},
	mixins: [vp],
	props: ["code", "sectionCode", "adding"],
	data() {
		return {}
	},
	computed: {
		isSaleOn(){
			return config.IS_SALE_ON
		},
		page() {
			return this.$store.state.page.ProductController;
		},
		categories() {
			return this.$store.state.common.sections;
		},
		item() {
			return this.page.item;
		},
		section() {
			return this.page.section;
		},
		basketAmount() {
			return this.$store.state.session?.basket?.reduce((total, el) => {
				if (Number(el.PRODUCT_ID) === Number(this.item.ID)) {
					total += el.AMOUNT;
				}
				return total;
			}, 0);
		},
		withInfo() {
			return this.item.ENERGY_KJ;
		},
		priceText() {
			return nf(this.item.PRICE, 2);
		},
		nameHtml() {
			const item = this.item;
			//return item.name.substr(0, 1).toUpperCase() + item.name.substr(1).toLowerCase();
			let name = item.NAME;
			name = name.replace(/(^.+?)(in )?(dark|milk).+$/is, "$1");
			name = name.replace(/(^.+?)filled with .+$/is, "$1");
			name = name.replace(/(^.+?)\d+ g$/is, "$1");
			name = name.replace(/(^.+?)(\d+ pcs)$/is, "$1");
			/*if ( name.match(/(^.+?)(\d+ pcs)$/is) ) {
				name = name.replace(/(^.+?)(\d+ pcs)$/is, "$1<br>$2");
				if ( item.WEIGHT ) name += " / "+item.WEIGHT + " g";
			}
			else if ( item.WEIGHT ) name += "<br>"+item.WEIGHT + " g";*/

			return name;
		},
		subnameHtml() {
			const item = this.item;
			if (item.SUBNAME) return item.SUBNAME;
			//return item.choco.substr(0, 1).toUpperCase() + item.choco.substr(1).toLowerCase();
			let choco = null;
			if (item.NAME.match(/.+?filled with .+$/)) {
				choco = item.NAME.replace(/.+?(filled with.+$)/is, "$1");
				choco = choco.replace(/(.+?)(\d+ g$)/is, "$1<br>$2");
			} else if (item.NAME.match(/.+?(in )?(dark|milk).+$/)) {
				choco = item.NAME.replace(/.+?((in )?(dark|milk).+$)/is, "$1");
				choco = choco.replace(/(.+?)(\d+ g$)/is, "$1<br>$2");
			} else if (item.NAME.match(/\d+ g$/)) {
				// todo make this related to item.WEIGHT
				choco = item.NAME.replace(/.+?(\d+ g$)/is, "$1");
			} else if (item.NAME.match(/\d+ pcs$/)) {
				choco = item.NAME.replace(/.+?(\d+ pcs$)/is, "$1");
				if (item.WEIGHT) choco += " / " + item.WEIGHT + " g";
			}

			return choco;
		},
		photo0() {
			//return this.item.PREVIEW_PICTURE;
			if (this.item.PHOTOS && this.item.PHOTOS[0]) return this.item.PHOTOS[0];
			//return this.item.DETAIL_PICTURE;
		},
		photo1() {
			//return this.item.DETAIL_PICTURE;
			//if ( this.item.DETAIL_PICTURE ) return null;
			return this.item.PHOTOS && this.item.PHOTOS[1];
		},
	},
	methods: {
		onImageClick() {
			if (this.isMD) return;

			if (this.item.PHOTOS && this.item.PHOTOS[0] && this.item.PHOTOS[1]) {
				const temp = this.item.PHOTOS.shift();
				this.item.PHOTOS.push(temp);
			}
		},
		onAddToBasket() {
			this.$emit("basket-add");
		},
	}
}
</script>

<style lang="scss">
@import "@/styles/base";

.product-without-content {

	&__text {
		position: relative;
		z-index: 3;

		&:before {
			@extend .abs;
			@include up($md) {
				content: "";
				width: 120%;
				height: 120%;
				background: $white;
				//mix-blend-mode: darken;
				opacity: 1;
				filter: blur(32px);
				//max-width: 1000px;
				//max-height: 1000px;
				z-index: -1;
				//border: 1px solid red;
			}
		}

		.btn-primary {
			width: 316px;
			padding: 0 !important;

			@include up($sm) {
				width: 320px;
			}
			@include up($md) {
				width: unset;
				padding: 0 28px !important;
			}
		}
	}

	&__title {

		h1 {
			font-size: $fs-h4;
			letter-spacing: $ls-h4;
			line-height: $lh-h4;

			@include up($sm) {
				font-size: $fs-h1;
				letter-spacing: $ls-h1;
				line-height: $lh-h1;
			}
		}
	}

	&__image {
		position: relative;
		//width: 125px;
		height: 320px;
		@include transition();

		@include up($md) {
			//width: 380px;
			height: 380px;
		}

		.hover {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			z-index: 2;
			//width: 100%;
			height: 100%;
			//background: rgba(0,0,0,0.2);
			//cursor: pointer;

			@include up($md) {
				&:hover .open {
					opacity: 1;
				}

				&:hover .closed {
					opacity: 0;
				}
			}
		}

		.v-image {
			height: 100%;
			//mix-blend-mode: multiply;
			//background-blend-mode: exclusion;
			filter: brightness(1.01); // contrast(1.02);	// для исчезновения в jpg серого фона
			@include transition();

			&.closed {
				z-index: 1;
			}

			&.open {
				position: absolute;
				top: 0;
				left: 0;
				//z-index: 1;
				opacity: 0;
			}
		}
	}

	&__note {
		max-width: 480px;
		margin: 0 auto;

		@include up($md) {
			margin: unset;
		}
	}

	&__more {
		span {
			display: inline-flex;
			align-items: center;

			svg {
				width: 12px;
				//transform: rotate(180deg);
				margin-left: 4px;
			}
		}
	}
}
</style>