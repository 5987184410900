<template>
	<div class="divider"></div>
</template>

<script>
    export default {
        name: "divider"
    }
</script>

<style lang="scss">
	.divider {
		width: 100%;
		height: 1px;
		background-color: $primary-100;
	}
</style>